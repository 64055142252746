/* eslint-disable import/prefer-default-export */
import { productListInterface } from 'interfaces/Product';
import api from 'services';
import convertQueryParamsConditionsAndFilterValues, { paramsQueryString } from 'utils/object';

const path = '/product';

export const getProductList = async (params: paramsQueryString): Promise<productListInterface> => {
  const response = await api.get<productListInterface>(
    `${path}/list`,
    { params: convertQueryParamsConditionsAndFilterValues(params) },
  );
  return response.data;
};
