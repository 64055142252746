/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { productListInterface } from 'interfaces/Product';
import { errorRedux } from 'interfaces/redux';
import { paramsQueryString } from 'utils/objects';
import { getProductList } from './service';

export const productList = createAsyncThunk<productListInterface, paramsQueryString, { rejectValue: errorRedux }>(
  'product/list',
  async (payload, { rejectWithValue }) => {
    try {
      const data = await getProductList(payload);
      return data;
    } catch (error) {
      const err = error as AxiosError<errorRedux>;
      return rejectWithValue(err.response?.data as errorRedux);
    }
  },
);

interface IProducts extends errorRedux {
  productsList: productListInterface;
}

const initialState: IProducts = {
  status: 'idle',
  message: '',
  productsList: {
    status: '',
    message: '',
    document: [],
    rowsAffected: [],
  },
};

export const ProductStatusReducer = createSlice({
  name: 'ProductStatus',
  initialState,
  reducers: {
    clearProductStatus: (state) => {
      state.status = 'idle';
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(productList.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(productList.fulfilled, (state, action) => {
        state.status = 'completed';
        state.productsList = action.payload;
        state.message = action.payload.message;
      })
      .addCase(productList.rejected, (state, action) => {
        state.status = 'failed';
        state.message = action.payload?.message || '';
      });
  },
});
export const { clearProductStatus } = ProductStatusReducer.actions;
export default ProductStatusReducer.reducer;
